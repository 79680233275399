import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useAuth, ContextHolder } from '@frontegg/react'
import Header from './components/Header'
import Home from './components/Home'
import Designer from './components/Designer'
import Login from './components/Login'
import OAuthCallback from './components/OAuthCallback' // New import
import { ReactFlowProvider } from 'reactflow'

function App() {
  const { isAuthenticated } = useAuth()

  // Ensure the Frontegg auth is loaded
  if (!ContextHolder.getContext()) {
    return <div>Loading...</div>
  }

  // If the user is not authenticated, show the Login component
  if (!isAuthenticated) {
    return <Login />
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/designer" element={
            <ReactFlowProvider>
              <Designer />
            </ReactFlowProvider>
          } />
          <Route path="/oauth/callback" element={<OAuthCallback />} /> {/* New OAuth callback route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  )
}

export default App