import React from 'react'
import { AppTile } from '../types'

interface DraggableAppTileProps {
  app: AppTile
}

const DraggableAppTile: React.FC<DraggableAppTileProps> = ({ app }) => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }

  return (
    <div
      onDragStart={(event) => onDragStart(event, app.id)}
      draggable
      className="bg-white p-4 rounded-lg shadow text-center cursor-move hover:shadow-md transition-shadow duration-200"
    >
      <div className="text-3xl mb-2">{app.icon}</div>
      <div className="font-medium text-sm">{app.name}</div>
    </div>
  )
}

export default DraggableAppTile