import React from 'react'
import { Workflow, LogOut } from 'lucide-react'
import { useAuth } from '@frontegg/react'
import { Link } from 'react-router-dom'

const Header: React.FC = () => {
  const { user, logout } = useAuth()

  const handleLogout = () => {
    logout()
  }

  return (
    <header className="bg-blue-600 text-white p-4">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center mb-4 sm:mb-0">
          <Workflow className="w-8 h-8 mr-2" />
          <span className="text-xl font-bold">FixFinder Workflow Orchestrator</span>
        </div>
        <nav className="flex items-center">
          <ul className="flex space-x-4 mr-4">
            <li>
              <Link to="/" className="hover:underline">Home</Link>
            </li>
            <li>
              <Link to="/designer" className="hover:underline">Designer</Link>
            </li>
            <li><a href="#" className="hover:underline">Templates</a></li>
            <li><a href="#" className="hover:underline">Integrations</a></li>
            <li><a href="#" className="hover:underline">Settings</a></li>
          </ul>
          <button
            onClick={handleLogout}
            className="flex items-center bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            <LogOut className="w-4 h-4 mr-2" />
            Logout
          </button>
        </nav>
      </div>
      {user && (
        <div className="container mx-auto mt-2 text-sm">
          <p>Logged in as: {user.name} ({user.email})</p>
        </div>
      )}
    </header>
  )
}

export default Header