import React, { useState } from 'react'
import { Workflow } from '../types'
import { Plus } from 'lucide-react'

interface WorkflowBuilderProps {
  addWorkflow: (workflow: Workflow) => void
}

const templates = [
  { id: 'blank', name: 'Blank template' },
  { id: 'user-onboarding', name: 'User onboarding' },
  { id: 'software-deployment', name: 'Software deployment' },
  { id: 'access-request', name: 'Access request approval' },
  { id: 'incident-response', name: 'Incident response' },
]

const WorkflowBuilder: React.FC<WorkflowBuilderProps> = ({ addWorkflow }) => {
  const [workflowName, setWorkflowName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState('blank')

  const handleCreateWorkflow = (e: React.FormEvent) => {
    e.preventDefault()
    if (workflowName.trim()) {
      addWorkflow({ 
        id: Date.now(), 
        name: workflowName.trim(), 
        template: selectedTemplate,
        steps: [] 
      })
      setWorkflowName('')
      setSelectedTemplate('blank')
    }
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Create New Workflow</h2>
      <form onSubmit={handleCreateWorkflow} className="space-y-4">
        <div>
          <label htmlFor="workflowName" className="block text-sm font-medium text-gray-700 mb-1">
            Workflow Name
          </label>
          <input
            type="text"
            id="workflowName"
            value={workflowName}
            onChange={(e) => setWorkflowName(e.target.value)}
            placeholder="Enter workflow name"
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div>
          <label htmlFor="templateSelect" className="block text-sm font-medium text-gray-700 mb-1">
            Select Template
          </label>
          <select
            id="templateSelect"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center"
        >
          <Plus className="w-5 h-5 mr-2" />
          Create Workflow
        </button>
      </form>
    </div>
  )
}

export default WorkflowBuilder