import React, { memo } from 'react'
import { Handle, Position, NodeProps } from 'reactflow'

const CustomNode = ({ data, selected }: NodeProps) => {
  return (
    <div 
      className={`bg-white rounded-lg shadow p-3 text-center relative flex items-center ${selected ? 'ring-2 ring-green-500' : ''}`}
      onDoubleClick={data.onDoubleClick}
    >
      <Handle
        type="target"
        position={Position.Left}
        className="w-3 h-3 !bg-blue-500 !border-2 !border-white left-0 top-1/2 transform -translate-y-1/2"
      />
      <div className="flex-1">
        <div className="text-3xl mb-2">{data.icon}</div>
        <div className="font-medium text-sm">{data.label}</div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        className="w-3 h-3 !bg-blue-500 !border-2 !border-white right-0 top-1/2 transform -translate-y-1/2"
      />
    </div>
  )
}

export default memo(CustomNode)