import React, { useState } from 'react'
import { Workflow } from '../types'
import WorkflowBuilder from './WorkflowBuilder'
import WorkflowList from './WorkflowList'

const Home: React.FC = () => {
  const [workflows, setWorkflows] = useState<Workflow[]>([])

  const addWorkflow = (workflow: Workflow) => {
    setWorkflows([...workflows, workflow])
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <WorkflowBuilder addWorkflow={addWorkflow} />
      <WorkflowList workflows={workflows} />
    </div>
  )
}

export default Home