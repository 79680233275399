import React from 'react'
import { useAuth, useLoginWithRedirect } from '@frontegg/react'
import { Lock } from 'lucide-react'

const Login: React.FC = () => {
  const { isAuthenticated } = useAuth()
  const loginWithRedirect = useLoginWithRedirect()

  // If the user is already authenticated, we don't need to show the login button
  if (isAuthenticated) {
    return null
  }

  return (
    <div className="min-h-screen bg-white flex items-center justify-center">
      <div className="w-full max-w-md px-6">
        <div className="flex justify-center mb-8">
          <img 
            src="https://cdn.prod.website-files.com/6656cc98d077ac69272f468d/6656d22b3e8622dd354bb35c_Logo.svg" 
            alt="FixFinder Logo" 
            className="w-[244px] h-[53px]"
          />
        </div>
        <h2 className="text-2xl font-bold mb-8 text-center text-gray-800">FixFinder Workflow Orchestrator</h2>
        <div className="flex justify-center">
          <button
            onClick={() => loginWithRedirect()}
            className="flex items-center justify-center px-6 py-2 bg-indigo-600 text-white text-sm font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition duration-300"
            style={{ width: '244px' }}
          >
            <Lock className="w-4 h-4 mr-2" />
            Single Sign-On
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login