import React, { useEffect, useRef } from 'react';
import { X } from 'lucide-react';

interface ConfigurationWindowProps {
  isOpen: boolean;
  onClose: () => void;
  position: { x: number; y: number };
  nodeName: string;
}

const ConfigurationWindow: React.FC<ConfigurationWindowProps> = ({ isOpen, onClose, position, nodeName }) => {
  const windowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && windowRef.current) {
      windowRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out" onClick={onClose} />
      <div
        ref={windowRef}
        className="fixed bg-white rounded-lg shadow-lg p-6 w-80 transition-all duration-300 ease-in-out"
        style={{
          top: `${position.y}px`,
          left: `${position.x}px`,
          transform: 'translate(-50%, -100%)',
        }}
        tabIndex={-1}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{nodeName} Configuration</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        <div className="space-y-4">
          <p className="text-gray-600">Options for this workflow step will appear here.</p>
          <div className="bg-gray-100 p-3 rounded">
            <p className="text-sm text-gray-500">Placeholder for future configuration options</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigurationWindow;