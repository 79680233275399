import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@frontegg/react';

const OAuthCallback: React.FC = () => {
  const { loginWithRedirect } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const nonce = params.get('nonce');

    if (code && nonce) {
      console.log('Authorization Code:', code);
      console.log('Nonce:', nonce);
      navigate('/'); // Redirect to home page after successful login
    } else {
      loginWithRedirect(); // If no code, redirect to login page
    }
  }, [location, navigate, loginWithRedirect]);

  return <p>Processing authentication...</p>;
};

export default OAuthCallback;