import React, { useState, useCallback, useRef } from 'react'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Connection,
  Edge,
  Node,
} from 'reactflow'
import 'reactflow/dist/style.css'
import { AppTile } from '../types'
import { Plus, Trash2 } from 'lucide-react'
import DraggableAppTile from './DraggableAppTile'
import CustomNode from './CustomNode'
import ConfigurationWindow from './ConfigurationWindow'

const appTiles: AppTile[] = [
  { id: 'gmail', name: 'Gmail', icon: '📧' },
  { id: 'gdrive', name: 'Google Drive', icon: '📁' },
  { id: 'gcalendar', name: 'Google Calendar', icon: '📅' },
  { id: 'slack', name: 'Slack', icon: '💬' },
  { id: 'trello', name: 'Trello', icon: '📊' },
  { id: 'dropbox', name: 'Dropbox', icon: '📦' },
  { id: 'datto', name: 'Datto', icon: '🔒' },
  { id: 'connectwise', name: 'ConnectWise', icon: '🔗' },
  { id: 'office365', name: 'Office365', icon: '📎' },
  { id: 'okta', name: 'Okta', icon: '🔑' },
  { id: 'sharepoint', name: 'SharePoint', icon: '📁' },
  { id: 'itglue', name: 'IT Glue', icon: '📘' },
  { id: 'adp', name: 'ADP', icon: '💼' },
  { id: 'workday', name: 'Workday', icon: '📅' },
]

const nodeTypes = {
  custom: CustomNode,
}

const Designer: React.FC = () => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [configWindowOpen, setConfigWindowOpen] = useState(false)
  const [configWindowPosition, setConfigWindowPosition] = useState({ x: 0, y: 0 })
  const [selectedNodeName, setSelectedNodeName] = useState('')
  const [selectedNode, setSelectedNode] = useState<Node | null>(null)

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  )

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect()
      const type = event.dataTransfer.getData('application/reactflow')

      if (typeof type === 'undefined' || !type || !reactFlowInstance) {
        return
      }

      const position = reactFlowInstance.project({
        x: event.clientX - (reactFlowBounds?.left ?? 0),
        y: event.clientY - (reactFlowBounds?.top ?? 0),
      })

      const app = appTiles.find((a) => a.id === type)
      if (!app) return

      const newNode: Node = {
        id: `${type}-${Date.now()}`,
        type: 'custom',
        position,
        data: { 
          label: app.name, 
          icon: app.icon,
          onDoubleClick: (event: React.MouseEvent) => {
            const nodeElement = event.target as HTMLElement
            const rect = nodeElement.getBoundingClientRect()
            setConfigWindowPosition({ x: rect.left + rect.width / 2, y: rect.top })
            setSelectedNodeName(app.name)
            setConfigWindowOpen(true)
          }
        },
      }

      setNodes((nds) => nds.concat(newNode))
    },
    [reactFlowInstance]
  )

  const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
    setSelectedNode(node)
  }, [])

  const onPaneClick = useCallback(() => {
    setSelectedNode(null)
  }, [])

  const deleteSelectedNode = useCallback(() => {
    if (selectedNode) {
      setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id))
      setEdges((eds) => eds.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id))
      setSelectedNode(null)
    }
  }, [selectedNode, setNodes, setEdges])

  const filteredApps = appTiles.filter((app) =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className="flex flex-col md:flex-row h-[calc(100vh-200px)] overflow-hidden">
      <div className="w-full md:w-1/4 bg-white p-4 border-r border-gray-200 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Apps</h2>
        <input
          type="text"
          placeholder="Search apps..."
          className="w-full p-2 border border-gray-300 rounded mb-4"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="grid grid-cols-2 gap-4">
          {filteredApps.map((app) => (
            <DraggableAppTile key={app.id} app={app} />
          ))}
        </div>
      </div>
      <ReactFlowProvider>
        <div className="w-full md:w-3/4 h-full relative" ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onNodeClick}
            onPaneClick={onPaneClick}
            nodeTypes={nodeTypes}
            fitView
          >
            <Controls />
          </ReactFlow>
          {nodes.length === 0 && (
            <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
              <div className="text-center text-gray-500 bg-white p-8 rounded-lg shadow">
                <Plus className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                <p className="text-lg font-semibold">Start building your workflow</p>
                <p className="mt-2">Drag and drop apps from the left to add steps to your workflow</p>
              </div>
            </div>
          )}
          {selectedNode && (
            <button
              className="absolute bottom-20 right-4 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors duration-200"
              onClick={deleteSelectedNode}
              title="Delete selected node"
            >
              <Trash2 size={20} />
            </button>
          )}
        </div>
      </ReactFlowProvider>
      <ConfigurationWindow
        isOpen={configWindowOpen}
        onClose={() => setConfigWindowOpen(false)}
        position={configWindowPosition}
        nodeName={selectedNodeName}
      />
    </div>
  )
}

export default Designer