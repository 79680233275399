import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { FronteggProvider } from '@frontegg/react'
import { BrowserRouter } from 'react-router-dom'

const contextOptions = {
  baseUrl: import.meta.env.VITE_APP_FRONTEGG_BASE_URL,
  clientId: import.meta.env.VITE_APP_FRONTEGG_CLIENT_ID
};

const authOptions = {
  keepSessionAlive: true,
  urlPrefix: '' // This should remove the '/frontegg' prefix
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <FronteggProvider 
        contextOptions={contextOptions} 
        hostedLoginBox={true}
        authOptions={authOptions}
      >
        <App />
      </FronteggProvider>
    </BrowserRouter>
  </React.StrictMode>,
)